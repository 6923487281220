<svelte:options immutable />

<script type="typescript">
  import { _ } from 'svelte-i18n';

  import { Link } from 'svelte-routing';
  import { queryGetTagID } from '../state/rsvp/store/rsvp.query';

  const tagId = queryGetTagID();

  const buttonClass = `flex border-white text-gray-100 font-bold w-full h-16 md:h-12 items-center justify-center min-w-100px px-4 py-1 text-sm hover:bg-blue-500`;

  const getProps = ({ href, isPartiallyCurrent, isCurrent }) => {
    let isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;

    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: 'bg-blue-500' };
    }
    return {};
  };
</script>

<style lang="scss">
  .toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateZ(10px);
  }

  .min-w-100px {
    min-width: 100px;
  }

  .min-w-fit-content {
    min-width: fit-content;
  }
</style>

<nav class="overflow-x-auto bg-blue-600 shadow w-full toolbar">
  <div
    class="min-w-fit-content grid grid-flow-col justify-center gap-0 grid-rows-none w-auto"
  >
    <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
    <Link to="/" getProps="{getProps}">
      <div class="{buttonClass}">{$_('page_title_home')}</div>
    </Link>
    <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
    <Link to="/location" getProps="{getProps}">
      <div class="{buttonClass}">{$_('page_title_location')}</div>
    </Link>
    <!-- {#if tagId}
      <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
      <Link to="e-invite" getProps="{getProps}">
        <div class="{buttonClass}">
          {$_('page_title_e_invite') + ' / ' + $_('page_title_rsvp')}
        </div>
      </Link>
    {/if} -->
    <!-- <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
    <Link to="live" getProps="{getProps}">
      <div class="{buttonClass}">{$_('page_title_stream')}</div>
    </Link> -->
    <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
    <Link to="gallery" getProps="{getProps}">
      <div class="{buttonClass}">{$_('page_title_gallery')}</div>
    </Link>
    <span class="block box-border border-l-2 border-blue-500" aria-hidden="true"></span>
  </div>
</nav>
