<script type="typescript">
  import {
    addMessages,
    init,
    getLocaleFromNavigator,
    getLocaleFromPathname,
  } from 'svelte-i18n';

  import en from '../i18n/en.json';
  import es from '../i18n/en.json';
  import hi from '../i18n/en.json';

  addMessages('en', en);
  addMessages('es', es);
  addMessages('hi', hi);

  init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromPathname(/^\/(.*?)\//) || getLocaleFromNavigator(),
  });
</script>
