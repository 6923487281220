<svelte:options immutable />

<script type="typescript">
  import { Router, Route } from 'svelte-routing';
  import Tailwind from './core/Tailwind.svelte';
  import I18n from './core/I18n.svelte';
  import Home from './routes/Home.svelte';
  import Location from './routes/Location.svelte';
  import Gallery from './routes/Gallery.svelte';
  import GalleryItem from './routes/GalleryItem.svelte';
  import { SUPPORTED_LANGUAGES } from './state/localization/models/lang.enum';
  import { locale } from 'svelte-i18n';
  import { setAppLocale } from './state/localization/state/lang.service';

  import Nav from './components/Nav.svelte';

  import { isLoading } from 'svelte-i18n';

  export let url = '';

  const languages = [...SUPPORTED_LANGUAGES, ''];

  locale.subscribe(() => setAppLocale($locale));

  // Done! Congratulations on your new bot. You will find it at t.me/save_our_date_bot. You can now add a description, about section and profile picture for your bot, see /help for a list of commands. By the way, when you've finished creating your cool bot, ping our Bot Support if you want a better username for it. Just make sure the bot is fully operational before you do this.

  // Use this token to access the HTTP API:
  // 1418946469:AAH-lGf4SsErScFhrJRO549_rl3SkP15Qkk
  // Keep your token secure and store it safely, it can be used by anyone to control your bot.

  // For a description of the Bot API, see this page: https://core.telegram.org/bots/api
</script>

<I18n />
<Tailwind />

{#if $isLoading}
  Please wait...
{:else}
  <Router url="{url}">
    {#each languages as lang}
      <Route path="{lang + '/'}rsvp/:id" let:params let:location>
        <Nav />
        <Location />
        <!-- <Rsvp params="{params}" location="{location}" /> -->
      </Route>
      <Route path="{lang + '/'}rsvp" let:params let:location>
        <Nav />
        <Location />
        <!-- <Rsvp params="{params}" location="{location}" /> -->
      </Route>
      <Route path="{lang + '/'}location" let:params let:location>
        <Nav />
        <Location />
      </Route>
      <Route path="{lang + '/'}live" let:params let:location>
        <Nav />
        <!-- <Live /> -->
        <Location />
      </Route>
      <Route path="{lang + '/'}e-invite" let:params let:location>
        <Nav />
        <!-- <EInvite /> -->
        <Location />
      </Route>
      <Route path="{lang + '/'}zoom" let:params let:location>
        <Nav />
        <!-- <Zoom /> -->
        <Location />
      </Route>
      <Route path="{lang + '/'}gallery" let:params let:location>
        <Nav />
        <Gallery />
      </Route>
      <Route path="{lang + '/'}gallery/picture/:id" let:params let:location>
        <Nav />
        <GalleryItem />
      </Route>
      <Route path="{lang ? lang : '/'}" let:params let:location>
        <Home />
      </Route>
    {/each}
  </Router>
{/if}
