<svelte:options immutable />

<script type="typescript">
  import { _ } from 'svelte-i18n';

  import Animation from '../components/Animation.svelte';
  import Nav from '../components/Nav.svelte';
  import { queryGetPictureID } from '../state/rsvp/store/rsvp.query';
</script>

<style lang="scss">
  main {
    text-align: center;
    padding: 0;
    margin: 0 auto;
  }

  .picture-frame {
    position: relative;
    width: 135px;
    height: 142px;
    margin: 0 auto;
    overflow: hidden;

    .picture {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 124px;
      height: 124px;
      border-radius: 50%;
    }
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<svelte:head>
  <title>{$_('page_title_home')}</title>
</svelte:head>

<main>
  <Nav />
  <span class="block box-border p-2" aria-hidden="true"></span>

  <div class="md:flex px-4 w-full md:w-2/3 lg:w-1/2 md:mx-auto">
    <div class="text-center rounded md:text-left p-6 w-full bg-white shadow">
      <div class="picture-frame">
        <img
          class="picture"
          src="/invitation-pictures/{queryGetPictureID() || '18'}.jpg"
          alt="couple"
          aria-hidden="true"
        />
        <Animation
          position="{'absolute'}"
          top="{'-80px'}"
          left="{'-64px'}"
          width="{'256px'}"
          height="{'256px'}"
        />
      </div>

      <span class="block box-border p-4" aria-hidden="true"></span>
      <span class="block box-border h-px bg-gray-200" aria-hidden="true"></span>
      <span class="block box-border p-4" aria-hidden="true"></span>
      <h2 class="text-xl text-center text-blue-700 font-bold">
        {$_('title_thanks_for_joining_us')}
      </h2>
      <!-- <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-left text-blue-900">
        {$_('home_message_line_1')}
      </p>
      <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-left text-blue-900">
        {$_('home_message_line_2')}
      </p> -->
      <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-left text-blue-900">
        {$_('home_message_line_3')}
      </p>
      <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-left text-blue-900">
        {$_('home_message_line_4')}
      </p>
      <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-left text-blue-900">
        {$_('home_message_line_5')}
      </p>
      <span class="block box-border p-4" aria-hidden="true"></span>
      <p class="text-lg text-center text-blue-500 font-bold">
        {$_('label_thanks')}
      </p>
      <p class="text-lg text-center text-blue-900 font-bold">
        {$_('Yago & Monica')}
      </p>
    </div>
  </div>
  <span class="block box-border p-16" aria-hidden="true"></span>

  <!-- Implement Countdown: https://codepen.io/doriancami/pen/jEJvaV -->
</main>
