<svelte:options immutable />

<script type="typescript">
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import {
    getAllPicturesAsync,
    monitorMediaChangesAsync,
  } from '../state/media/state/media.service';
  import { querySelectPictures } from '../state/media/state/media.query';

  let observingMedia = null;
  let observingFetchMedia = null;

  observingFetchMedia = getAllPicturesAsync();

  const mediaFiles = querySelectPictures;

  onMount(() => {
    observingMedia = monitorMediaChangesAsync();
  });

  onDestroy(() => {
    if (observingMedia) {
      observingMedia.unsubscribe();
    }

    if (observingFetchMedia) {
      observingFetchMedia.unsubscribe();
    }
  });
</script>

<style lang="scss">
  main {
    text-align: center;
    padding: 0;
    margin: 0 auto;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<svelte:head>
  <title>{$_('page_title_gallery')}</title>
</svelte:head>

<main>
  <div
    class="p-6 lg:w-3/5 sm:w-full max-w-none m-auto grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6"
  >
    TODO: Add Picture Preview
  </div>
</main>
