<svelte:options immutable />

<script type="typescript">
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import {
    getAllPicturesAsync,
    monitorMediaChangesAsync,
  } from '../state/media/state/media.service';
  import { querySelectPictures } from '../state/media/state/media.query';

  let observingMedia = null;
  let observingFetchMedia = null;

  observingFetchMedia = getAllPicturesAsync();

  const mediaFiles = querySelectPictures;

  onMount(() => {
    observingMedia = monitorMediaChangesAsync();
  });

  onDestroy(() => {
    if (observingMedia) {
      observingMedia.unsubscribe();
    }

    if (observingFetchMedia) {
      observingFetchMedia.unsubscribe();
    }
  });
</script>

<style lang="scss">
  main {
    text-align: center;
    padding: 0;
    margin: 0 auto;
  }

  .min-w-64px {
    min-width: 64px;
  }

  .telegram-bg {
    background-color: #0b8dc4;

    &:hover {
      background-color: lighten($color: #0b8dc4, $amount: 10);
    }
  }

  .whatsapp-bg {
    background-color: #4caf50;

    &:hover {
      background-color: lighten($color: #4caf50, $amount: 10);
    }
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<svelte:head>
  <title>{$_('page_title_gallery')}</title>
</svelte:head>

<main>
  <div
    class="p-6 lg:w-3/5 sm:w-full max-w-none m-auto grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6"
  >
    <!-- <div
      class="flex flex-row justify-center items-center bg-white rounded overflow-hidden p-6 shadow col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4"
    >
      <div class="h-full min-w-64px pr-6 flex flex-row items-start justify-center">
        <img class="w-16 h-16" src="/whatsapp-telegram-mixed.svg" alt="whatsapp logo" />
      </div>
      <div class="flex flex-col justify-start items-start w-full">
        <h2 class="font-bold text-left">{$_('title_share_your_pictures')}</h2>
        <h3 class="font-normal text-left">{$_('message_share_your_pictures')}</h3>
        <span class="block box-border p-3" aria-hidden="true"></span>
        <div class="w-full flex flex-col items-center justify-end sm:flex-row">
          <a
            class="font-bold self-end telegram-bg px-4 py-2 text-white rounded shadow w-full sm:w-auto"
            href="https://t.me/save_our_date_bot"
            target="_blank"
            rel="noopener noreferrer">{$_('btn_install_telegram_bot')}</a
          >
          <span class="block box-border p-2" aria-hidden="true"></span>
          <a
            class="font-bold self-end whatsapp-bg px-4 py-2 text-white rounded shadow  w-full sm:w-auto"
            href="https://wa.me/17404019257"
            target="_blank"
            rel="noopener noreferrer"
          >
            {$_('btn_install_whatsapp_bot')}
          </a>
        </div>
      </div>
    </div> -->
    {#each $mediaFiles as file}
      <div class="bg-white rounded overflow-hidden p-4 md:p-4 lg:p-2 shadow">
        {#if file.type === 'photo' || `${file.mimeType || ''}`.indexOf('image/') !== -1}
          <div class="md:flex aspect-ratio-square relative">
            <img
              class="absolute w-full h-full object-cover object-center bg-gray-600"
              src="{file.url}"
              alt="{file.name}"
            />
          </div>
        {:else if file.type === 'video' || `${file.mimeType || ''}`.indexOf('video/') !== -1 || `${file.name || ''}`.indexOf('.mp4') !== -1}
          <div class="md:flex aspect-ratio-square relative">
            <video
              class="absolute w-full h-full object-cover object-center bg-gray-600"
              width="{file.width}"
              height="{file.height}"
              controls
              autoplay
              muted
              loop
            >
              <source src="{file.url}" type="{file.mimeType || 'video/mp4'}" />
            </video>
          </div>
        {:else}
          <div class="flex flex-col aspect-ratio-square relative">
            <h3>{file.type}</h3>
            <h3>{file.name}</h3>
            <p>{file.url}</p>
          </div>
        {/if}

        <div class="flex items-center justify-end box-border h-8 md:h-8">
          <a
            href="{file.url}"
            class="option-button w-5 h-5"
            alt="{file.name}"
            title="download"
            target="_blank"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true"
              ><defs
                ><style>
                  .a {
                    fill: none;
                    stroke: #436bb7;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                </style></defs
              ><title>cloud-download</title><line
                class="a"
                x1="12"
                y1="14.25"
                x2="12"
                y2="23.25"></line><polyline
                class="a"
                points="15.75 19.5 12 23.25 8.25 19.5"></polyline><path
                class="a"
                d="M16.5,15.75h1.875a4.875,4.875,0,1,0-2.764-8.885,7.5,7.5,0,1,0-8.149,8.844"
              ></path></svg
            >
          </a>
        </div>
      </div>
    {/each}
  </div>
</main>
