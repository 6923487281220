<script type="typescript">
  import { fade } from 'svelte/transition';
  import { onMount } from 'svelte';
  import lottie from 'lottie-web/build/player/lottie_light';

  export let position = 'relative';
  export let top = 'auto';
  export let left = 'auto';
  export let width = '124px';
  export let height = '124px';

  let animationObject = null;
  let animationElement = null;

  onMount(async () => {
    animationObject = lottie.loadAnimation({
      container: animationElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/lottie/ring-of-fire.json',
      name: 'test',
    });
  });

  function handleStop() {
    animationObject.stop();
  }

  function handlePlay() {
    animationObject.play();
  }
</script>

<svelte:options immutable />
<section
  transition:fade="{{ duration: 200 }}"
  class="m-auto rounded-full mt-6"
  style="position: {position};top: {top};left: {left};width: {width};height:{height};overflow:hidden;"
>
  <div class="w-full h-full rounded-full" bind:this="{animationElement}"></div>
</section>
