<svelte:options immutable />

<script type="typescript">
  import { _ } from 'svelte-i18n';
</script>

<style lang="scss">
  main {
    text-align: center;
    padding: 0;
    margin: 0 auto;
  }

  .min-w-200px {
    min-width: 200px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<svelte:head>
  <title>{$_('page_title_location')}</title>
</svelte:head>

<main>
  <div
    class="grid grid-cols-1 grid-rows-1 sm:grid-cols-1 sm:grid-rows-1 gap-4 justify-center items-start rounded w-full max-w-none m-auto"
  >
    <div class="flex align-top justify-center p-8">
      <!-- <div
        class="flex flex-col w-96 min-w-200px justify-center bg-white rounded-lg p-0 shadow overflow-hidden"
      >
        <img
          src="/location_pictures/castrelos.jpg"
          alt="invitation picture"
          aria-hidden="true"
        />
        <span class="block box-border h-4" aria-hidden="true"></span>
        <div class="md:flex rounded-lg p-2 flex-col w-full">
          <h2 class="text-xl text-center text-gray-900 font-bold">
            {$_('title_ceremony_location')}
          </h2>
          <span class="block box-border h-2" aria-hidden="true"></span>

          <h3 class="text-xl text-center text-gray-700">
            {$_('description_ceremony_location')}
          </h3>
          <span class="block box-border h-2" aria-hidden="true"></span>
          <h4 class="font-bold text-gray-800">27.March.2021</h4>
          <span class="block box-border h-2" aria-hidden="true"></span>
          <p class="text-gray-600">Museo Quiñones de León</p>
          <p class="text-gray-600">36213 Vigo, Pontevedra</p>
          <span class="block box-border h-2" aria-hidden="true"></span>
          <a
            href="http://www.museodevigo.org/"
            hreflang="es-ES"
            class="text-blue-600 underline">http://www.museodevigo.org/</a
          >
        </div>
        <span class="block box-border h-4" aria-hidden="true"></span>
        <iframe
          title="{$_('title_ceremony_location')}"
          class="rounded w-full"
          width="100%"
          height="300"
          frameborder="0"
          style="border:0"
          tabindex="0"
          src="https://www.google.com/maps/embed/v1/view?key=AIzaSyAm0CX9-PD1vL2abcFGhK0kt6afjHrmMNA
      &center=42.213215,-8.727607&zoom=18&maptype=satellite"
          allowfullscreen></iframe>
      </div>
    </div> -->

      <!-- <div class="flex align-top justify-start p-8">
        <div
          class="lex flex-col w-96 min-w-200px justify-center bg-white rounded-lg p-0 shadow overflow-hidden"
        >
          <img
            src="/location_pictures/casona.jpg"
            alt="invitation picture"
            aria-hidden="true"
          />
          <span class="block box-border h-4" aria-hidden="true"></span>
          <div class="md:flex rounded-lg p-2 flex-col w-full">
            <h2 class="text-xl text-center text-gray-900 font-bold">
              {$_('title_party_location')}
            </h2>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <h3 class="text-xl text-center text-gray-700">
              {$_('description_party_location')}
            </h3>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <h4 class="font-bold text-gray-800">06.June.2021</h4>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <p class="text-gray-600">Estrada Subida Cedeira, 2</p>
            <p class="text-gray-600">36812 Redondela, Pontevedra</p>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <a
              href="http://www.casonadatorre.com/"
              hreflang="es-ES"
              class="text-blue-600 underline">http://www.casonadatorre.com/</a
            >
          </div>
          <span class="block box-border h-4" aria-hidden="true"></span>

          <iframe
            title="{$_('title_party_location')}"
            class="rounded w-full"
            width="100%"
            height="300"
            frameborder="0"
            style="border:0"
            tabindex="0"
            src="https://www.google.com/maps/embed/v1/view?key=AIzaSyAm0CX9-PD1vL2abcFGhK0kt6afjHrmMNA
      &center=42.28222511641271,-8.619041575505769&zoom=18&maptype=satellite"
            allowfullscreen></iframe>
        </div>
      </div> -->

      <div class="flex align-top justify-start p-8">
        <div
          class="lex flex-col w-96 min-w-200px justify-center bg-white rounded-lg p-0 shadow overflow-hidden"
        >
          <img
            src="/location_pictures/radison.jpg"
            alt="invitation picture"
            aria-hidden="true"
          />
          <span class="block box-border h-4" aria-hidden="true"></span>
          <div class="md:flex rounded-lg p-2 flex-col w-full">
            <h2 class="text-xl text-center text-gray-900 font-bold">
              {$_('title_party_location')}
            </h2>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <h3 class="text-xl text-center text-gray-700">
              {$_('description_party_location')}
            </h3>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <h4 class="font-bold text-gray-800">20.November.2021</h4>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <p class="text-gray-600">Country Inn & Suites by Radisson</p>
            <p class="text-gray-600">Navi Mumbai</p>
            <p class="text-gray-600">022 6159 4000</p>
            <p class="text-gray-600">5th floor</p>
            <span class="block box-border h-2" aria-hidden="true"></span>
            <a
              href="https://www.radissonhotels.com/en-us/hotels/country-inn-navi-mumbai?cid=a:se+b:gmb+c:apac+i:local+e:rdb+d:ind+h:INMHNAVMUM"
              hreflang="es-ES"
              class="text-blue-600 underline">https://www.radissonhotels.com/</a
            >
          </div>
          <span class="block box-border h-4" aria-hidden="true"></span>

          <iframe
            title="{$_('title_party_location')}"
            class="rounded w-full"
            width="100%"
            height="300"
            frameborder="0"
            style="border:0"
            tabindex="0"
            src="https://www.google.com/maps/embed/v1/view?key=AIzaSyAm0CX9-PD1vL2abcFGhK0kt6afjHrmMNA&center=19.113766,73.012398&zoom=18&maptype=satellite"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</main>
